// components.tsx
import type { FC } from 'react';

import { Link } from '@remix-run/react';

import styles from './styles.module.css';

import { withStyledComponents } from '../shared/with-styled-component';
import classNames from 'classnames';

// StyledAvatar
export const StyledAvatar: FC<any> = ({ children, className }) => (
  <div className={classNames(styles.avatar, className)}>{children}</div>
);

// StyledLink
export const StyledLink = withStyledComponents(styles.link, Link);

// LogoutButton
export const LogoutButton: FC<any> = ({ onClick, children, className }) => (
  <button onClick={onClick} className={classNames(styles.button, className)}>
    {children}
  </button>
);

// AccountMenu
export const AccountMenu: FC<any> = ({ className, children, shown, moreMenu, offset }) => (
  <ul
    style={{ right: offset?.right ? offset.right : undefined }}
    className={`${styles.menu} ${className} ${shown ? styles.shown : ''} ${moreMenu ? styles.moreMenu : ''}`}
  >
    {children}
  </ul>
);

// Header
export const Header: FC<any> = ({ children, className }) => (
  <div className={classNames(styles.header, className)}>{children}</div>
);

// LeftWrapper
export const LeftWrapper: FC<any> = ({ children, className }) => (
  <div className={classNames(styles.wrapper, className)}>{children}</div>
);
